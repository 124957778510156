// Styling for EULAs, the content of which is retrieved from the help pages.

.licence-text {
  .green {
    color: #008000;
  }

  .orange {
    color: #c27515;
  }

  .red {
    color: #f00;
  }

  .symbol {
    font-size: 34px;
    font-weight: bold;
  }

  table {
    &.license-table {
      font-size: 20px;

      thead {
        font-size: larger;
      }

      tbody {
        font-size: 20px;
      }

      th,
      td {
        vertical-align: top;
        padding: 0.5em;
      }

      li {
        padding-bottom: 15px;
      }
    }
  }

  .license-list {
    padding-left: 20px;

    > li {
      padding-left: 15px;

      &::marker {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
      }
    }

    &.license-have {
      list-style-type: '\2713';

      > li::marker {
        color: #008000;
      }
    }

    &.license-must {
      list-style-type: '!';

      > li::marker {
        color: #db7b00;
      }
    }

    &.license-dont {
      list-style-type: '\00d8';

      > li::marker {
        font-size: 25px;
        color: #f00;
      }
    }
  }

  .lower-latin-list {
    list-style-type: lower-alpha;
  }

  .lower-roman-list {
    list-style-type: lower-roman;
  }

  .visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
