.footer {
  position: absolute;
  height: $footer-height;
  bottom: 0;
  width: 100%;
  background-color: $inverse-color;
  color: $secondary-color;

  .edina-footer {
    display: flex;
    background-color: #000;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      flex: 1 1 33%;
    }
  }

  .footer-centre {
    padding: 0.5em;
    justify-content: center;
  }

  .footer-right {
    justify-content: flex-end;
    @media screen and (max-width: 530px) {
      flex-wrap: wrap;
    }
  }

  a {
    fill: $secondary-color;
    color: $secondary-color;
    padding: 0.2em;

    &:hover {
      color: darken($secondary-color, 30%);
      fill: darken($secondary-color, 30%);
    }

    &.btn {
      &.btn-inverse {
        padding-right: 1em;
      }
    }
  }
}

.copyright {
  display: flex;
  background-color: $primary-color;
  border-radius: 0;

  a {
    color: $inverse-color;

    &:hover {
      color: $brand-primary;
    }
  }
}

// SVG Icons.
// Sadly width: auto; doesn't work in IE11 :(
.icon-chat {
  width: 5em;
  height: 3em;
}

.icon-twitter {
  width: 4em;
  height: 3em;
}

.icon-facebook {
  width: 3em;
  height: 3em;
}

.icon-blog {
  width: 3em;
  height: 3em;
}

.icon-youtube {
  width: 3em;
  height: 3em;
}

// The list of social media icons (chat, twitter, youtube, etc.)
.media-icons {
  text-align: right;
  @media screen and (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.icon-edina {
  width: 7em;
  height: 3em;

  // @media screen and (max-width: $screen-xs-max) {
  //   // need to use center-block here to center the image
  //   @include center-block;
  //   margin-bottom: .75em;
  // }
}
