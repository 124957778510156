.verify-notification {
  .verify-notification-button-container {
    display: flex;

    .verify-notification-button-label {
      margin-top: 0.5em;
      min-width: 11.5em;
    }

    .verify-notification-button {
      margin-bottom: 0.5em;
      margin-left: 0.5em;
    }
  }
}
