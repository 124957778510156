//
// Font styles.
//
//
@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('../../assets/fonts/MYRIADPRO-REGULAR.woff') format('woff');
}
