.questions-accordion {
  .mat-expansion-panel {
    margin-bottom: 16px;
    border-radius: 4px !important;

    &.mat-expanded > .mat-expansion-panel-header {
      background: rgba(0, 0, 0, 0.08);
      background: $primary-color-light;

      .mat-expansion-panel-header-title,
      .mat-expansion-indicator::after {
        color: #fff;
      }
    }

    .mat-expansion-panel-header {
      padding: 3em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .mat-expansion-panel-header-title {
        font-family: $default-font-family;
        font-weight: bold;
        color: $primary-color;
        padding: 0.5em;
        font-size: 1.75em;
      }
    }

    .mat-expansion-panel-body {
      padding-top: 1em;
    }

    .mat-expansion-indicator::after {
      border-width: 0 4px 4px 0;
      padding: 6px;
    }

    li {
      list-style: disc;
    }
  }
}

.wrap-text {
  width: 40em;
  word-wrap: break-word;
  white-space: pre-line;
}

.important {
  color: $error-color;
}

.sub-heading {
  font-size: smaller;
}

.explanation-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.explanation-content {
  width: 70vw;
}

.info-link {
  cursor: pointer;
}

.help-checkboxes {
  input {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  a {
    margin-left: 0.5em;
  }
}

.btn-warning {
  color: #000;

  &:hover {
    color: #000;
  }
}
