.header-alert {
  z-index: 2;
  position: relative;

  .alert {
    padding-left: 0;
    border: 0;
  }

  .alert-offset {
    @include make-lg-column-offset(1.5);
  }

  div {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: bold;
  }
}

.collection-link-container {
  // Should not set padding-left here. Collection links are assigned the page-padding-left
  // class which will set the padding-left property to the page-wide value.
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid $primary-color;
  white-space: nowrap;

  .row {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 0.25em;
  }
}

.collection-link-container-top {
  border-top: 0;
}

.digimap-main-text {
  @media screen and (max-width: $screen-xs-max) {
    flex-direction: column;
  }
}
