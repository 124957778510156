//
// Base stylesheet.
// Should define common styles used throughout the application.
// Should be defined from specific to generic
//
* {
  margin: 0;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: $footer-height;
  font-family: $default-font-family;
  font-size: $default-font-size;
  background-color: $inverse-color;
}

a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  padding: 0;
  height: 100%;
  background-color: $inverse-color;
}

.bottom,
.push {
  height: 15px;
}

button,
a {
  &.btn {
    &.btn-main {
      background-color: $primary-color;
      color: $secondary-color;
      text-transform: capitalize;
      font-size: 0.8em;
      font-weight: bold;

      &:hover {
        background-color: lighten($primary-color, 10%);
      }

      &:focus {
        background-color: lighten($primary-color, 10%);
      }
    }
  }
}

.btn-inverse {
  background-color: transparent;
  color: $primary-color;
}

.btn-blank {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.primary-link {
  color: $primary-color;
}

.primary-link-inverse {
  color: $primary-color;
}

.btn,
.primary {
  &:hover,
  &:focus {
    color: $active-color;
  }
}

// SVG image icons
.icon {
  display: inline-block;
  -webkit-mask-size: cover;
  background-size: cover;
  width: 7%;
  height: 3em;
  background-color: $primary-color;

  &:hover,
  &:focus {
    background-color: $active-color;
  }
}

// Used for form fields.
input,
select,
textarea {
  &.ng-valid {
    border-left: 5px solid $valid-color; // green
  }

  &.ng-invalid {
    border-left: 5px solid $error-color; // red
  }

  // Set the text of the label to look disabled.
  &[type='checkbox'] {
    &[disabled] {
      + label {
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

dm-control-messages {
  div {
    margin: 6px 0;
    color: $error-color;
  }
}

.highlight {
  color: #0385dc;
}

.important-highlight {
  color: $brand-alert;
}

.row {
  &.no-gutters {
    margin-left: 0;
    margin-right: 0;

    > [class^='col-'],
    > [class*=' col-'] {
      padding-left: 0;
      padding-right: 0;
    }

    > .dm-viewport {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

// The main content area in forms and other information pages such as logout
.dm-viewport {
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #fff;
}

// Form headings
.dm-form-heading {
  @include make-xs-column-offset(0);
  @include make-md-column-offset(1);
}

// Form label layout
.dm-form-label {
  @include make-xs-column(3);
}

// General form control layout
.dm-form-control {
  @include make-xs-column(9);
  @include make-sm-column(8);
  @include make-md-column(7);
  @include make-lg-column(5);
}

// Form checkbox layout
.dm-form-checkbox {
  @include make-xs-column-offset(3);
  @include make-xs-column(9);
  @include make-sm-column(8);
  @include make-md-column(7);
  @include make-lg-column(5);
}

// Layout of form buttons
.dm-form-buttons {
  @include make-xs-column-offset(3);
}

.footnote {
  color: $alert-warning-text;
  font-size: 90%;
}

.non-supported-browser {
  margin: 0.2em 0;
  padding: 0.2em 0;
  font-size: 2em;
  background: #ddd;
  color: #000;
}

// Left padding for the page. Need to use viewport relative units (or absolute units
// such as pixels) since this class will be applied to various different types of
// element, e.g. the heading, the collection tab buttons, etc., and we want the
// margin to be a consistant size throughout the whole page.
.page-padding-left {
  padding-left: 2vw;

  @media (min-width: $screen-lg-min) {
    padding-left: 8vw;
  }
}

// Right padding for the page (with the same restrictions on the units used as the
// page-padding-left above). The right margin is collapsed to zero for all but
// large screens.
.page-padding-right {
  padding-right: 0;

  @media (min-width: $screen-lg-min) {
    padding-right: 8vw;
  }
}

.visuallyhidden {
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
