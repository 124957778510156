.activate-collections {
  .mat-expansion-panel {
    margin-bottom: 16px;
    border-radius: 4px !important;

    .mat-expansion-panel-header {
      padding: 2.5em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      color: $primary-color;
      fill: $primary-color;

      &.active {
        background-color: $state-success-bg;
        color: $state-success-text;
        fill: $state-success-text;
      }

      &.deactivated {
        background-color: $state-danger-bg;
        color: $state-danger-text;
        fill: $state-danger-text;
      }

      &.queried {
        background-color: $state-warning-bg;
        color: $state-warning-text;
        fill: $state-warning-text;
      }

      .mat-expansion-panel-header-title {
        font-family: $default-font-family;
        font-weight: bold;
        padding: 0.5em;
        font-size: 1.75em;
        color: inherit;
        justify-content: space-between;

        svg {
          margin-right: 0.25em;
          width: 1.75em;
          height: 1.75em;
          vertical-align: middle;
        }

        .panel-title-left {
          display: flex;
          flex-direction: row;
        }

        .licence-status {
          text-transform: uppercase;
          font-size: 15px;
          align-self: center;
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        padding: 5em 1em;

        .mat-expansion-panel-header-title {
          flex-direction: column;

          .panel-title-left {
            flex-direction: column;
          }

          .licence-status {
            align-self: flex-end;
            justify-self: flex-end;
          }
        }
      }
    }

    .mat-expansion-panel-body {
      padding-top: 1em;
    }
  }

  .licence-accepted {
    margin-right: 0.5em;
  }
}

.licence-area {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1em;
  height: 16em;
  overflow-y: scroll;
}

.licence {
  ol,
  ul {
    margin-top: 10px;
  }

  .licence-text {
    li {
      margin-bottom: 10px;
      list-style: inherit;
    }
  }
}

.licence-copyright {
  padding: 10px;
  border: 1px solid #000;
}

.licence-yes {
  margin-right: 0.3em;
  color: $brand-success;
  font-size: 1.5em;
}

.licence-warning {
  margin-right: 0.3em;
  color: $brand-alert;
  font-size: 1.5em;
}

.licence-no {
  margin-right: 0.3em;
  color: $brand-danger;
  font-size: 1.5em;
}

.padding-left {
  padding-left: 40px;
}

.related-licences {
  display: flex;
  align-items: baseline;
  padding-left: 0;

  & .glyphicon {
    margin-right: 0.25em;
  }
}

embed {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 16em;
}
