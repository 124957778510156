.header {
  background-color: $primary-color;
  position: relative;
  z-index: 3;
}

.header-content {
  display: flex;
  margin: 0 1em;
  justify-content: space-between;
  font-size: 1.3em;
}

.header-top-right {
  display: flex;
  flex-direction: row;
}

.social-icons {
  margin-right: 1em;
}

a {
  &.social-icon {
    fill: $secondary-color;
    color: $secondary-color;
    padding: 0.2em;

    &:hover {
      color: darken($secondary-color, 30%);
      fill: darken($secondary-color, 30%);
    }

    * {
      //margin-top: 0.5em;
      height: 22px;
      width: auto;
    }
  }
}

.header-strapline {
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  align-self: flex-end;
}

.digimap-logo {
  width: 15em;
  height: 5em;

  // Reduce size of digimap logo on small screens
  @media screen and (max-width: $screen-xs-max) {
    width: 11em;
    height: 3.8em;
  }
}

.header-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (min-width: $screen-xs-max) {
    min-width: 439px;
  }

  .btn {
    border-radius: 0;
  }

  div {
    padding: 0.3em 0;
  }

  .login-btn {
    background-color: $brand-primary;
    color: $secondary-color;
    font-weight: bold;
    box-shadow: $standard-box-shadow;
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: 0.5em;

    &:hover {
      background-color: lighten($brand-primary, 10%);
    }
  }
}

.resource-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

// Need to specify li here to override bootstrap otherwise
// the selector isn't specific enough
.header-top-btns {
  li {
    a {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.header-bottom-btns {
  li {
    a {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.btn-burger {
  @include button-variant($primary-color, $secondary-color, $secondary-color);
  font-size: 1.2em;

  &[aria-expanded='false'] {
    color: $primary-color;
    background-color: $secondary-color;
  }

  &[aria-expanded='true'] {
    color: $secondary-color;
    background-color: $primary-color;
  }
}

.xs-welcome-message {
  padding: 0.5em 0;

  span {
    padding-left: 0.75em;
  }
}

.xs-dropdown-menu {
  font-size: 1.1em;
}

.access-text-color {
  color: $secondary-color;
}

.header-help-text {
  display: flex;
  align-items: center;
  text-align: right;
}

.header-link {
  color: #3fb1fd;
  margin-left: 0.3em;

  &:hover {
    color: #4fc1ff;
  }

  &:focus {
    color: #4fc1ff;
  }
}
