.simple-notification {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  transition-duration: 0.5s;

  .title {
    margin: 0;
    padding: 0;
    line-height: 30px;
    font-size: 20px;
  }

  .content {
    margin: 0;
    font-size: 16px;
    padding: 0 50px 0 0;
    line-height: 20px;
  }

  svg {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    right: 0;
    width: auto;
    height: 70px;
    padding: 10px;
    fill: #fff;
  }

  &.rtl-mode {
    direction: rtl;

    .content {
      padding: 0 0 0 50px;
    }

    svg {
      left: 0;
      right: auto;
    }
  }

  &.error {
    background: $brand-danger;

    .progress {
      span {
        background: $alert-danger-border;
      }
    }
  }

  &.success {
    background: $brand-success;

    .progress {
      span {
        background: $alert-success-border;
      }
    }
  }

  &.alert {
    background: $brand-warning;

    .progress {
      span {
        background: $alert-warning-border;
      }
    }
  }

  &.info {
    background: $brand-info;

    .progress {
      span {
        background: $alert-info-border;
      }
    }
  }

  &.bare {
    .progress {
      span {
        background: #ccc;
      }
    }
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;

    span {
      float: left;
      height: 100%;
    }
  }
}

$notification-width: 300px;

.simple-notification-wrapper {
  position: fixed;
  top: 175px;
  left: 50%;
  width: $notification-width;
  margin-left: $notification-width / -2;
  z-index: 1000;
}
