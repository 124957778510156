.newsflash {
  background-color: $brand-newsflash;
  color: $font-color-default;

  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }
}

.close {
  opacity: 0.8;
}
