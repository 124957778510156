// sass-lint:disable no-ids

.app-container {
  svg {
    width: 1.75em;
    height: 1.75em;
    vertical-align: middle;
    fill: $secondary-color;
  }
}

.title {
  svg {
    width: 55px;
    height: 55px;
    margin-top: 0.15em;
  }
}

.app-disabled {
  svg {
    fill: #666;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .collection-detail-container {
    flex-direction: column;

    &.logged-in {
      flex-direction: column-reverse;
    }

    div {
      &.description {
        flex: auto;
      }

      &.apps {
        flex: auto;
      }
    }
  }
}

.mat-tab-group {
  .mat-tab-label {
    &.mat-focus-indicator,
    &.cdk-keyboard-focused {
      color: #000;
    }

    &.mat-tab-label-active {
      opacity: 1;
      background-color: $primary-color-light;
      color: #fff;
    }

    &:not(.mat-tab-label-active):hover {
      opacity: 1;
      color: #000;
    }

    .mat-tab-label-content {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .mat-tab-body {
    border-top: 1px solid #bbb;
    padding: 15px;

    p,
    ul,
    ol {
      line-height: 1.5em;
      font-size: 18px;
    }
  }
}

td {
  padding: 5px;
}

th {
  padding: 5px;
}
