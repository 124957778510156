//
// Variables
//
// NOTE this file is shared by both main.scss and vendor.scss

// Bootstrap Overrides
//$icon-font-path: "../../../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/" !default;

$icon-font-path: '../../../assets/fonts/bootstrap/';

$font-color-default: rgba(0, 0, 0, 0.8);

$default-font-family: 'Myriad Pro Regular';
$default-font-size: 1.5em;

$primary-color: #2c3e62;
$primary-color-light: #45619e;
$secondary-color: #fff;

$brand-primary: #027aca;
$brand-success: #3a833a;
$brand-danger: #d33;
$brand-alert: #ffcc02;
$brand-newsflash: #ff3;
$brand-alert-dark: #cc6200;
$brand-info: $brand-primary;
$btn-info-color: $primary-color;

// The collection application buttons
$btn-primary-bg: transparent;
$btn-primary-color: $brand-primary;
$btn-primary-border: #7c8fad;

$inverse-color: #e4e9ec;
$active-color: $secondary-color;
// $active-inverse-color: #1495fa;

$valid-color: $brand-success;
$error-color: $brand-danger;

$alert-warning-text: #8a6d3b;

// We need to shift the collections a few pixels down to make the edge of the blur effect
// look better. However, we also shift the whole parent div up by the same amount
// to eliminate the gap created at the top by doing this.
$blur-top-margin: 5px;

// The minimum height used for the components on the home page
// This helps line them up vertically
$home-component-height: 669px;

$footer-height: 112px;

$standard-box-shadow: 0 3px 5px -1px rgba(8, 5, 5, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
  0 1px 18px 0 rgba(0, 0, 0, 0.12);
